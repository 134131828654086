
  /* Modal */
  .modal-wrapper {
    width: 500px;
  }
  
  .modal {
    background: var(--background-rgb);
    height: 100%;
    width: 100%;
    border-radius: 15px;
    padding: 15px;
    z-index: 10;
  }
  
  .modal svg path {
    fill: var(--foreground-rgb);
  }
  
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-body {
    padding-top: 10px;
  }