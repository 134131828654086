@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dark-grey: 26, 26, 26;
  --near-white: white;
  --foreground-rgb: rgb(var(--dark-grey));
  --background-rgb: var(--near-white);
  --dark-green: 0, 111, 129;
  --light-green: 83, 207, 186;
  --dark-pink: 237, 43, 146;
  --light-pink: 248, 170, 221;
  --dark-yellow: 174, 149, 21;
  --light-yellow: 250, 241, 192;
  --green: rgb(var(--dark-green));
  --pink: rgb(var(--dark-pink));
  --yellow: rgb(var(--dark-yellow));
}

html.dark {
  --foreground-rgb: var(--near-white);
  --background-rgb: rgb(var(--dark-grey));
  --green: rgb(var(--light-green));
  --pink: rgb(var(--light-pink));
  --yellow: rgb(var(--light-yellow));
}

body {
  color: var(--foreground-rgb);
  background: var(--background-rgb);
}

h1 {
  color: var(--green);
  font-size: 3rem;
}

h2 {
  color: var(--yellow);
  font-size: 2rem;
}

h3 {
  color: var(--green);
  font-size: 1.5rem;
}

input {
  background-color: var(--background-rgb);
}

textarea {
  background-color: var(--background-rgb);
}

.pink-text {
  color: var(--pink);
}

.circle {
  display: inline-block;
  border-radius: 50%;
  background-color: var(--pink);
  padding: 0.5rem;
  min-width: 2rem;
  min-height: 2rem;
  text-align: center;
  vertical-align: middle;
}

.circle.unselected {
  background-color: rgb(113, 113, 122);
}

.circle svg path {
  fill: var(--background-rgb);
}

.circle.unselected svg path {
  fill: var(--near-white);
}